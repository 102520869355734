import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Ticker from 'react-ticker'

import starGreen from '../../assets/images/base/star-green.png'


const useStyles = makeStyles((theme) => ({
  ticker: {
    marginTop: '67vh',
    marginBottom: '15vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '46vh',
      marginBottom: '39vh',
    },
  },
  title: {
    fontSize: '50px',
    color: '#00FF00',
    whiteSpace: "nowrap",
    fontFamily: 'FreightBigBook',
    fontWeight: 200,
    letterSpacing: '2px',
    '&::selection': {
      backgroundColor: '#000'
    },
    '&:hover':{
      textDecoration: 'none'
    },
    marginRight:'10px',
    display: 'inline'
  },
  star: {
    margin: '10px 10px 0px 10px',
    width: '23px',
    '&::selection': {
      backgroundColor: '#000'
    },
    display: 'inline'
  },}));

const TickerText = ({name, title, date}) => {
  const classes = useStyles()
  return <span>
    <h1 className={classes.title}>
    {name}
    <img
      className={classes.star}
      src={starGreen} alt='Nelson Makengo'
    />
    {title}
    {date && (
    <>
      <img
      className={classes.star}
      src={starGreen} alt='Nelson Makengo'
      />
      {date}
    </>
    )}
    </h1>
  </span>
};


export default function Page(props) {
  const classes = useStyles()

  return (
    <div className={classes.ticker}>
      <Ticker mode='smooth' speed={7}>
        {() => <TickerText 
          name={props.name}
          title={props.title}
          date={props.date}/>
        }
        </Ticker>
      </div>
  )
}
