import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Star from './Star'

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '2px solid #00FF00',
    backgroundColor: "#d6d7d6",
    padding: "100px 300px 100px 300px",
    [theme.breakpoints.down('lg')]: {
      padding: '100px 300px 100px 300px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '100px 200px 100px 200px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '100px 30px 100px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '100px 20px 100px 20px',
    },
  },
}));

export default function Album({children}) {
  const classes = useStyles();

  return (
    <>
    <Star/>
    <div className={classes.root}>
      {children}
    </div>
    </>
  );
}