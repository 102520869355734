import React, {useState} from 'react';
import ReactPlayer from 'react-player/soundcloud'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import PlayPause from './PlayPause'
const useStyles = makeStyles((theme) => ({
  appBar: {
    borderTop: '2px solid #00FF00',
    paddingTop: '50px',
    paddingBottom: '50px',
    backgroundColor: "#00FF00",
    
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  play: {
    margin: '0px 50px 0px 50px',
  },
  tagline: {
    margin: '20px',
  },
  player: {
    visibility: 'hidden',
    display: 'none'
  },
  title: {
    lineHeight: '80%'
  },
  subtitle: {
    lineHeight: '80%',
    fontFamily: 'FreightBigBook',
  }
}));

export default function Default({title, subtitle, caption, image, color, url}) {
  const classes = useStyles();
  const [playing, SETPlaying] = useState(false)

  const setPlaying = (e) => {
    SETPlaying(e)
  }

  return (
      <AppBar position="static" color="transparent" elevation={0}
        className={classes.appBar}
        style={{backgroundImage: `url(${image})`,}}
      >
      <ReactPlayer
        className={classes.player}
        playing={playing}
        url={url}
      />

        <Toolbar className={classes.toolbar}
        disableGutters
        >

          <div className={classes.play}>
          <PlayPause className={classes.play}
          setPlaying={setPlaying}
          playing={playing}
          />
          </div>

          <div className={classes.tagline}
            style={{color: color}}          
          >
          <Typography 
            component='h3'
            variant="h3"
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography 
            className={classes.subtitle}
            component='h3'
            variant="h3"
          >
            {subtitle}
          </Typography>
          <Typography 
            component='p'
            variant="body1"
          >
            {caption}
          </Typography>
          </div>

        </Toolbar>
      </AppBar>
  );
}


