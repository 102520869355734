import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import left from '../../assets/images/base/left-white.png' 
import right from '../../assets/images/base/right-white.png' 
import { PinDropSharp } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1100,
    flexGrow: 1,
    margin: '20px auto 0px auto',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      margin: '60px 30px 0px 30px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 'auto',
    display: 'block',
    maxWidth: 1100,
    overflow: 'hidden',
    width: '100%',
  },
  layerLeft: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:hover':{
      cursor: `url(${left}) 16 16, auto`
    },
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    height: '95%',
    zIndex: '1'
  },
  layerRight: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:hover':{
      cursor: `url(${right}) 16 16, auto`
    },
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50%',
    height: '95%',
    zIndex: '1'
  }
}));

function SwipeableTextMobileStepper({slides, title}) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = slides.length;

  const handleNext = () => {
    if (activeStep === maxSteps-1) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps-1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
    <div className={classes.root}>
      <div className={classes.layerLeft}
        onClick={handleBack}
      ></div>
      <div className={classes.layerRight}
        onClick={handleNext}
      ></div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {slides.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.image} alt={step.label} />
            ) : null}
          </div>
        ))}
      </SwipeableViews>

      {title ? (
      <Typography align='left'
      variant='subtitle1'
        style={{margin: '10px 0px 0px 0px'}}
      >
        {title}
      </Typography>
      ): (
        <>
          <Typography align='left'
            variant='subtitle1'
              style={{margin: '10px 0px 0px 0px'}}
            >
              {slides[activeStep].label}
         </Typography>
        </>
      )}

      
    </div>

    <Typography align='right'
    variant='subtitle1'
      style={{padding: '0px 10px 10px 0px'}}
    >
    {activeStep + 1}/{maxSteps}
    </Typography>
  </>
  );
}

export default SwipeableTextMobileStepper;
