import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { StarGreen } from './Star'
import about from '../../assets/images/base/about.png'
import rhul from '../../assets/images/base/rhul.jpg'
import flux from '../../assets/images/base/flux.png'
import { LinkOut } from '../../components/Link'

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: "#fff",
    borderTop: '2px solid #00FF00',
    padding: "50px 100px 50px 100px",
    [theme.breakpoints.down('lg')]: {
      padding: '50px 200px 50px 200px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '100px 90px 100px 90px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '100px 30px 100px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '100px 20px 100px 20px',
    },
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'left',
    background: 'transparent'
  },
  paper2: {
    padding: theme.spacing(2),
    textAlign: 'right',
    maxWidth: '250px',
    background: 'transparent'
  },
  paper3: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'transparent'
  },
  about: {
    width: '240px',
    margin: '15px'
  },
  rhul: {
    height: '80px',
    margin: '15px'
  },
  flux: {
    height: '80px',
    margin: '15px'
  },
  social: {
  },
}));

const initial = {
  FNAME: '',
  EMAIL: '',
}

export default function FullWidthGrid() {
  const classes = useStyles();
  const [sent, setSent] = useState(false)
  const [ticket, setTicket] = useState(initial)
  
  const handleSent = () => {
    setSent(true);
  };

  const submit = e => {
    e.preventDefault()
    fetch('https://api.skllchain.com/skllchain/anon/demo', {
      method: 'POST',
      body: JSON.stringify(ticket),
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.json())
    .then(handleSent)
    .catch()
  }

  return (
    <>
    <StarGreen/>
    <div className={classes.root}>
      <Container maxWidth='xl'>
          <Grid container spacing={0}
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
              <Paper className={classes.paper1} elevation={0}
              >
                {[
                  {
                    title: 'Email', url: 'mailto:info@demomovingimage.com'
                  },
                  {
                    title: 'Facebook', url: 'https://www.facebook.com/demomovingimage'
                  },
                  {
                    title: 'Instagram', url: 'https://www.instagram.com/demomovingimage'
                  },
                  {
                    title: 'Twitter', url: 'https://twitter.com/demomovingimage'
                  },
                ].map((obj, i) => (
                <Typography key={i}
                  className={classes.social}
                  variant='subtitle1'
                >
                <LinkOut href={obj.url}
                  color="inherit"
                >
                  {obj.title}
                </LinkOut>
              </Typography>
              ))}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper2} elevation={0}>
              {sent ? (
                <Typography
                className={classes.social}
                variant='h6'
              >
              Subscribed.
            </Typography>
              ) : (
                <form onSubmit={submit}>
                <TextField label="Name" value={ticket.FNAME} name="FNAME"
                  type="text" fullWidth
                  onChange={e => setTicket({ ...ticket, FNAME: e.target.value })}
                />
                <TextField label="Email" value={ticket.EMAIL} name="EMAIL" 
                  type="email" required fullWidth
                  onChange={e => setTicket({ ...ticket, EMAIL: e.target.value })}
                />
                <br />
                  <Button type="submit"
                  >
                    Subscribe
                  </Button>
                  </form>
              )}

              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper3} elevation={0}
              >
                <img src={about} alt="Arts council england" 
                  className={classes.about}
                />
                <img src={rhul} alt="Royal Holloway" 
                  className={classes.rhul}
                />
                <img src={flux} alt="Fluxus" 
                  className={classes.flux}
                />
              </Paper>
            </Grid>
          </Grid>
          </Container>
        </div>
    </>
  );
}
