import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Star from './Star'
import Carousel from '../Carousel'

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '2px solid #00FF00',
    backgroundColor: "#d6d7d6",
  },
}));

export default function Album({slides, title}) {
  const classes = useStyles();

  return (
    <>
    <Star/>
      <div className={classes.root}>

      <Carousel slides={slides} title={title}/>
      
      </div>
    </>
  );
}