import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import play from '../../assets/images/base/play.png'
import pause from '../../assets/images/base/pause.png'
import { usePodcast } from "../../context"

const useStyles = makeStyles((theme) => ({
  play: {
    display: 'inline',
    margin: '0px 0px 0px 10px',
    width: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      margin: '13px 10px 20px 0px',
    },
    '&::selection': {
      backgroundColor: '#666'
    },
    cursor: 'pointer'
  },

}));

export default function Album(props) {
  const classes = useStyles();
  const {playing, setPlaying} = props
  return (
    <>
          {playing ? (
            <img className={classes.play} src={pause} alt="Demo Moving Image"
              onClick={() => {
                setPlaying(false)
              }}
          />
          ) : (
            <img className={classes.play} src={play} alt="Demo Moving Image"
              onClick={() => {
                setPlaying(true)
              }}
            /> 
          )}
    </>
  );
}