import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Star from './Star'
import {LinkIn} from '../Link'

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '2px solid #00FF00',
    backgroundColor: "#d6d7d6",
    padding: "50px 50px 50px 50px",
    [theme.breakpoints.down('lg')]: {
      padding: '50px 50px 50px 50px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '50px 50px 50px 50px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 30px 50px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '50px 20px 50px 20px',
    },
  },
  title: {
    marginTop: '40px',
    lineHeight: '80%',
  },
  subtitle: {
    lineHeight: '80%',
    marginBottom: '20px',
    fontFamily: 'FreightBigBook',
  }, 
  article: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function Album({url, title, subtitle, children}) {
  const classes = useStyles();

  return (
    <>
    <Star/>
    <div className={classes.root}>
      <Grid container>
        <Grid item md={6}>
        <Typography variant='h3'
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography variant='h3'
         className={classes.subtitle}
        >
          {subtitle}
        </Typography>
        </Grid>
        <Grid item md={6}
          className={classes.article}
        >
          <LinkIn 
            to={url}
            color='inherit'
            style={{textDecoration:'none'}}
          >
          {children}    
          [continue reading...]   
          </LinkIn>
          </Grid>
      </Grid>
    </div>
    </>
  );
}