import React, {useState, useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReactPlayer from 'react-player/vimeo'
import star from '../../assets/images/base/star.png'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import play from '../../assets/images/base/play-outlined.png'
import pause from '../../assets/images/base/pause-outlined.png'
import { useIdleTimer } from 'react-idle-timer'
import Star from './Star'

import fullscreen from '../../assets/images/base/fullscreen.png'
import screenfull from 'screenfull'

const useStyles = makeStyles((theme) => ({
  film: {
    borderTop: '2px solid #00FF00',
    backgroundColor: "#000",
    display: 'flex',
    flexDirection: 'column',
    minHeight: '96vh',
    position: 'relative',
  },
  star: {
    position: 'absolute',
    top: '13px',
    left: '20px',
    width: '50px',
    [theme.breakpoints.down('md')]: {
      width: '40px',
    },
    zIndex: '3'
  },
  full: {
    width: '50px',
    position: 'absolute',
    top: '83vh',
    right: '40px',
    '&:hover': {
      width: '55px',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      right: '20px',
      '&:hover': {
        width: '33px',
        cursor: 'pointer'
      },
    },
    '&::selection': {
      backgroundColor: '#666'
    },
  },
  wrap: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    overflow: "hidden",
   },
   player: {
    width: "100vw",
    height: "56.25vw", /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    minHeight: "100vh",
    minWidth: "177.77vh", /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
   },
  paper: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height:'100vh',
    width:'100%',
    backgroundColor:'transparent',
    zIndex: '400',
  },
  blob: {
    height: '12vh',
    width: '12vh',
    '&:hover': {
      height: '12.5vh',
      width: '12.5vh',
      cursor: 'pointer'
    },
    display: 'block',
    margin: 'auto',
    zIndex: '400',
    opacity: '0.80'
  },
  title: {
    position: 'absolute',
    top: '73vh',
    color: '#fff',
    margin: '0px 0px 0px 40px',
    [theme.breakpoints.down('sm')]: {
      top: '17vh',
      margin: '0px 0px 0px 20px',
    },
    '&::selection': {
      backgroundColor: '#000'
    },
    zIndex: 700
  },
  subtitle: {
    position: 'absolute',
    top: '71vh',
    fontSize: '25px',
    color: '#fff',
    fontWeight: 200,
    letterSpacing: '2px',
    margin: '0px 0px 0px 25vh',
    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
      margin: '0px 0px 0px 40px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '20vh',
      fontSize: '15px',
      margin: '0px 0px 0px 20px',
    },
    '&::selection': {
      backgroundColor: '#000'
    },
    letterSpacing: '1px',
    lineHeight: '23pt',
    zIndex: 700
  }
}));

export default function Page({url, title, titleShort},props) {
  const classes = useStyles()
  const [playing, setPlaying] = useState(false)
  const timeout = 3000
  const [idle, setIdle] = useState(false)
  const handleOnActive = () => setIdle(false)
  const handleOnIdle = () => playing && setIdle(true)
  useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  const playVideo = () => {
    const payload = {target: titleShort}
    try {
      fetch('https://api.skllchain.com/skllchain/anon/p', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
      }).catch((e) => {
        console.log(e)
      })
    } catch (e) {
      console.log(e)
    }
  }

  const player = useRef(null);
  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(player.current.wrapper);
    }
  }

  return (
    <div className={classes.film}>
      {idle === false && (
      <img className={classes.star} src={star} alt="Instagram"/>  
      )}
    <div className={classes.wrap}>
      <ReactPlayer
        ref={player}
        className={classes.player}
        url={`https://player.vimeo.com/video/${url}`}
        playing={playing}
        
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        // controls
      />
    </div>
    {idle === false && (
      <>
      <Typography
        component='h2'
        variant='h6'
        className={classes.title}
      >
      {title}
      </Typography>

    <GridSub
      playing={playing} 
      setPlaying={setPlaying}
      playVideo={playVideo}
    />
      {playing && (
      <img src={fullscreen} alt='Document'
        className={classes.full}
        onClick={handleClickFullscreen}
      />
      )}
     </>
    )}
    </div>
  );
}

const GridSub = ({ playing, setPlaying, playVideo }) => {
  const classes = useStyles();
  return (
  <div style={{
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '300',
  }}>
  <Grid container
    justify='center'
  >
      <Grid item xs>
          <Paper className={classes.paper}
          elevation={0}
          >
          {playing ? (
            <img alt='pause' className={classes.blob} 
            src={pause}
            onClick={() => setPlaying(!playing)}
            />

          ) : (
            <img alt='play' className={classes.blob} 
            src={play}
            onClick={() => {setPlaying(!playing); playVideo()}}
            />
          )}

          </Paper>
      </Grid>
  </Grid>
  </div>
)
}


